const SectionData = (sections, content, currentSectionIndex, i18n) => {

    return {
            header: {
              name: 'header',
              formFields: [
                { type: 'text', name: 'title', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].title : null, label: i18n({ id: "sites_content.header.title.label" }), required: false },
                { type: 'text', name: 'subtitle', value: sections && sections.length > 0  && sections[currentSectionIndex] ? sections[currentSectionIndex].subtitle : null, label: i18n({ id: "sites_content.header.subtitle.label" }), required: false },
                { type: 'text', name: 'link_text', value: sections && sections.length > 0  && sections[currentSectionIndex] ? sections[currentSectionIndex].link_text : null, label: i18n({ id: "sites_content.header.link_text.label" }), required: false },
                { type: 'link', name: 'link', value: sections && sections.length > 0  && sections[currentSectionIndex] ? sections[currentSectionIndex].link : null, label: i18n({ id: "form.link.label" }), required: false },
                { type: 'image', name: 'image', value: sections && sections.length > 0  && sections[currentSectionIndex] ? sections[currentSectionIndex].image : null, label: i18n({ id: "sites_content.header.image.label" }), required: false },
                { type: 'text', name: 'alt_text', value: sections && sections.length > 0  && sections[currentSectionIndex] ? sections[currentSectionIndex].alt_text : null, label: i18n({ id: "sites_content.header.alt_text.label" }), required: false },
              ],
              datafields: {
                title: content ? content.company_name : "",
                subtitle: content.industry ? i18n({ id: `sites_content.header.subtitle.${content.industry}` }) : null,
                link_text: content.industry ? i18n({ id: `sites_content.header.link_text.${content.industry}` }) : null,
                image_url: content.industry ? i18n({ id: `sites_content.header.image.${content.industry}` }) : null,
                alt_text: content.industry ? i18n({ id: `sites_content.header.alt_text.${content.industry}` }) : null,
              }
            },
            services: {
              name: 'services',
              formFields: [
                { type: 'text', name: 'title', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].title : null, label: i18n({ id: "sites_content.services.title.label" }), required: false },
                { type: 'text', name: 'services', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].services : null, label: i18n({ id: "sites_content.services.services.label" }), help: i18n({ id: "pages.dashboard.browser.comma_separated_list" }), required: false }
              ],
              datafields: {
                title: content.industry ? i18n({ id: `sites_content.services.title.${content.industry}` }) : null,
                services: content.industry ? i18n({ id: `sites_content.services.services.${content.industry}` }) : null
              }
            },
            image_text_right: {
              name: 'image_text_right',
              formFields: [
                { type: 'text', name: 'title', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].title : null, label: i18n({ id: "sites_content.image_text_right.title.label" }), required: false },
                { type: 'textarea', name: 'subtitle', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].subtitle : null, label: i18n({ id: "sites_content.image_text_right.text.label" }), required: false },
                { type: 'text', name: 'button_text', value: sections && sections.length > 0  && sections[currentSectionIndex] ? sections[currentSectionIndex].button_text : null, label: i18n({ id: "sites_content.image_text_right.button_text.label" }), required: false },
                { type: 'link', name: 'link', value: sections && sections.length > 0  && sections[currentSectionIndex] ? sections[currentSectionIndex].link : null, label: i18n({ id: "form.link.label" }), required: false },
                { type: 'image', name: 'image', value: sections && sections.length > 0  && sections[currentSectionIndex] ? sections[currentSectionIndex].image : null, label: i18n({ id: "sites_content.image_text_right.image.label" }), required: false },
                { type: 'text', name: 'alt_text', value: sections && sections.length > 0  && sections[currentSectionIndex] ? sections[currentSectionIndex].alt_text : null, label: i18n({ id: "sites_content.image_text_right.alt_text.label" }), required: false },
              ],
              datafields: {
                title: content.industry ? i18n({ id: `sites_content.image_text_right.title.${content.industry}` }) : null,
                subtitle: content.industry ? i18n({ id: `sites_content.image_text_right.text.${content.industry}` }) : null,
                button_text: content.industry ? i18n({ id: `sites_content.image_text_right.button_text.text.${content.industry}` }) : null,
                image_url: content.industry ? i18n({ id: `sites_content.image_text_right.image.${content.industry}` }) : null,
                alt_text: content.industry ? i18n({ id: `sites_content.image_text_right.alt_text.${content.industry}` }) : null,
              }
            },
            image_text_left: {
              name: 'image_text_left',
              formFields: [
                { type: 'text', name: 'title', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].title : null, label: i18n({ id: "sites_content.image_text_left.title.label" }), required: false },
                { type: 'textarea', name: 'subtitle', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].subtitle : null, label: i18n({ id: "sites_content.image_text_left.text.label" }), required: false },
                { type: 'text', name: 'button_text', value: sections && sections.length > 0  && sections[currentSectionIndex] ? sections[currentSectionIndex].button_text : null, label: i18n({ id: "sites_content.image_text_left.button_text.label" }), required: false },
                { type: 'link', name: 'link', value: sections && sections.length > 0  && sections[currentSectionIndex] ? sections[currentSectionIndex].link : null, label: i18n({ id: "form.link.label" }), required: false },
                { type: 'image', name: 'image', value: sections && sections.length > 0  && sections[currentSectionIndex] ? sections[currentSectionIndex].image : '', label: i18n({ id: "sites_content.image_text_left.image.label" }), required: false },
                { type: 'text', name: 'alt_text', value: sections && sections.length > 0  && sections[currentSectionIndex] ? sections[currentSectionIndex].alt_text : null, label: i18n({ id: "sites_content.image_text_left.alt_text.label" }), required: false },
              ],
              datafields: {
                title: content.industry ? i18n({ id: `sites_content.image_text_left.title.${content.industry}` }) : null,
                subtitle: content.industry ? i18n({ id: `sites_content.image_text_left.text.${content.industry}` }) : null,
                button_text: content.industry ? i18n({ id: `sites_content.image_text_left.button_text.text.${content.industry}` }) : null,
                image_url: content.industry ? i18n({ id: `sites_content.image_text_left.image.${content.industry}` }) : null,
                alt_text: content.industry ? i18n({ id: `sites_content.image_text_left.alt_text.${content.industry}` }) : null
              }
            },
            contact_section: {
              name: 'contact_section',
              formFields: [
                { type: 'text', name: 'title', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].title : null, label: i18n({ id: `sites_content.contact_section.title.label` }), required: false },
                { type: 'text', name: 'text', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].text : null, label: i18n({ id: `sites_content.contact_section.text.label` }), required: false },
                { type: 'text', name: 'button_text', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].button_text : null, label: i18n({ id: `sites_content.contact_section.button_text.label` }), required: false },
                { type: 'text', name: 'phone', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].phone : null, label: i18n({ id: `sites_content.contact_section.phone.label` }), required: false },
              ],
              datafields: {
                title: content.industry ? i18n({ id: `sites_content.contact_section.title.${content.industry}` }) : null,
                button_text: content.industry ? i18n({ id: `sites_content.contact_section.button_text.${content.industry}` }) : null,
                text: content.industry ? i18n({ id: `sites_content.contact_section.text.${content.industry}` }) : null,
                phone: content.contact_phone
              }
            },
            contact_simple: {
              name: 'contact_simple',
              formFields: [
                { type: 'text', name: 'phone_text', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].phone_text : null, label: i18n({ id: `sites_content.contact_simple.phone_text.label` }), required: false },
                { type: 'text', name: 'phone', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].phone : null, label: i18n({ id: `sites_content.contact_simple.phone_number.label` }), required: false },
                { type: 'textarea', name: 'phone_time', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].phone_time : null, label: i18n({ id: `sites_content.contact_simple.phone_time.label` }), required: false },
                { type: 'text', name: 'email_text', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].email_text : null, label: i18n({ id: `sites_content.contact_simple.email_text.label` }), required: false },
                { type: 'email', name: 'email', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].email : null, label: i18n({ id: `sites_content.contact_simple.email.label` }), required: false },
              ],
              datafields: {
                phone_text: content.industry ? i18n({ id: `sites_content.contact_simple.phone_text.${content.industry}` }) : null,
                phone: content.contact_phone,
                phone_time: content.industry ? i18n({ id: `sites_content.contact_simple.phone_time.${content.industry}` }): null,
                email_text: content.industry ? i18n({ id: `sites_content.contact_simple.email_text.${content.industry}` }): null,
                email: '',
              }
            },
            footer: {
              name: 'footer',
              description: i18n({ id: `sites_content.footer.description` }),
              formFields: [
                { type: 'text', name: 'footer_title_one', value: content && content.footer_title_one, label: i18n({ id: `sites_content.footer.title_one.label` }), required: false },
                { type: 'textarea', name: 'footer_text_one', value: content && content.footer_text_one, label: i18n({ id: `sites_content.footer.text_one.label` }), required: false },
                { type: 'text', name: 'footer_title_two', value: content && content.footer_title_two, label: i18n({ id: `sites_content.footer.title_two.label` }), required: false },
                { type: 'textarea', name: 'footer_text_two', value: content && content.footer_text_two, label: i18n({ id: `sites_content.footer.text_two.label` }), required: false },
                { type: 'text', name: 'footer_title_three', value: content && content.footer_title_three, label: i18n({ id: `sites_content.footer.title_three.label` }), required: false },
                { type: 'textarea', name: 'footer_text_three', value: content && content.footer_text_three, label: i18n({ id: `sites_content.footer.text_three.label` }), required: false },
              ],
              datafields: {
                footer_title_one: content.industry ? i18n({ id: `sites_content.footer.title_one.${content.industry}` }) : null,
                footer_text_one: content.industry ? i18n({ id: `sites_content.footer.text_one.${content.industry}` }) : null,
                footer_title_two: content.industry ? i18n({ id: `sites_content.footer.title_two.${content.industry}` }) : null,
                footer_text_two: content.industry ? i18n({ id: `sites_content.footer.text_two.${content.industry}` }) : null,
                footer_title_three: content.industry ? i18n({ id: `sites_content.footer.title_three.${content.industry}` }) : null,
                footer_text_three: content.industry ? i18n({ id: `sites_content.footer.text_three.${content.industry}` }) : null
              }
            },
            why_us: {
              name: 'why_us',
              formFields: [
                { type: 'text', name: 'title', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].title : null, label: i18n({ id: `sites_content.why_us.title.label` }), required: false },
                { type: 'textarea', name: 'text', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].text : null, label: i18n({ id: `sites_content.why_us.text.label` }), required: false },
                { type: 'text', name: 'title_one', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].title_one : null, label: i18n({ id: `sites_content.why_us.title_one.label` }), required: false },
                { type: 'textarea', name: 'text_one', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].text_one : null, label: i18n({ id: `sites_content.why_us.text_one.label` }), required: false },
                { type: 'text', name: 'title_two', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].title_two : null, label: i18n({ id: `sites_content.why_us.title_two.label` }), required: false },
                { type: 'textarea', name: 'text_two', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].text_two : null, label: i18n({ id: `sites_content.why_us.text_two.label` }), required: false },
                { type: 'text', name: 'title_three', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].title_three : null, label: i18n({ id: `sites_content.why_us.title_three.label` }), required: false },
                { type: 'textarea', name: 'text_three', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].text_three : null, label: i18n({ id: `sites_content.why_us.text_three.label` }), required: false },
              ],
              datafields: {
                title: content.industry ? i18n({ id: `sites_content.why_us.title.${content.industry}` }) : null,
                text: content.industry ? i18n({ id: `sites_content.why_us.text.${content.industry}` }) : null,
                title_one: content.industry ? i18n({ id: `sites_content.why_us.title_one.${content.industry}` }) : null,
                text_one: content.industry ? i18n({ id: `sites_content.why_us.text_one.${content.industry}` }) : null,
                title_two: content.industry ? i18n({ id: `sites_content.why_us.title_two.${content.industry}` }) : null,
                text_two: content.industry ? i18n({ id: `sites_content.why_us.text_two.${content.industry}` }) : null,
                title_three: content.industry ? i18n({ id: `sites_content.why_us.title_three.${content.industry}` }) : null,
                text_three: content.industry ? i18n({ id: `sites_content.why_us.text_three.${content.industry}` }) : null
              }
            },
            code: {
              name: 'code',
              formFields: [
                { type: 'text', name: 'title', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].title : null, label: i18n({ id: "sites_content.code.title.label" }), required: false },
                { type: 'textarea', name: 'text', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].text : null, label: i18n({ id: "sites_content.code.text.label" }), required: false },
                { type: 'textarea', name: 'embed', value: sections && sections.length > 0  && sections[currentSectionIndex] ? sections[currentSectionIndex].embed : '', label: i18n({ id: "sites_content.code.embed.label" }), required: false },
              ],
              datafields: {
                title: content.industry ? i18n({ id: `sites_content.code.title.${content.industry}` }) : null,
                text: content.industry ? i18n({ id: `sites_content.code.text.${content.industry}` }) : null,
                embed: content.industry ? i18n({ id: `sites_content.code.embed.${content.industry}` }) : null
              }
            },
            text: {
              name: 'text',
              formFields: [
                { type: 'text', name: 'title', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].title : null, label: i18n({ id: "sites_content.code.title.label" }), required: false },
                { type: 'textarea', name: 'text', value: sections && sections.length > 0 && sections[currentSectionIndex] ? sections[currentSectionIndex].text : null, label: i18n({ id: "sites_content.code.text.label" }), required: false },
              ],
              datafields: {
                title: content.industry ? i18n({ id: `sites_content.text.title.${content.industry}` }) : null,
                text: content.industry ? i18n({ id: `sites_content.text.text.${content.industry}` }) : null
              }
            },
          }  
}

export default SectionData