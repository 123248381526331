import React from 'react'
import { useIntl } from "gatsby-plugin-intl"

import Classes from './notification.module.scss'

const Notification = ({ text }) => {
    const i18n = useIntl().formatMessage

    return (
        <div className={Classes.bar}>
            <p>{i18n({ id: "general.promo_banner" })}</p>
        </div>
    )
}

export default Notification