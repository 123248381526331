import {createSiteRequest, createSiteSuccess, createSiteFailure, accessToken} from '../actions/auth';
import saveAction from './authSaveData'

const axios = require('axios');

function createSite(site_id, domain, tld, accessToken) {
    const domainEnding = tld === 'da' ? 'dk' : 'com'
    return dispatch => {

        dispatch(createSiteRequest());

        axios.post('/.netlify/functions/netlifyCreateSite', {
            method: 'post',
            endpoint: null,
            data: {
                domain, 
                domainEnding, 
                site_id
            }
        })
        // axios({
        //     method: 'post',
        //     url: `https://api.netlify.com/api/v1/sites`,
        //     data: {
        //         name: `${domain}.worksiter.${domainEnding}`,
        //         custom_domain: `${domain}.worksiter.${domainEnding}`,
        //         domain_aliases: [`www.${domain}.worksiter.${domainEnding}`],
        //         url: `https://${domain}.worksiter.${domainEnding}`,
        //         force_ssl: true,
        //         repo: {
        //             provider:"gitlab",
        //             id:74777123,
        //             repo: "mamacph/builder",
        //             private:true,
        //             branch:"master",
        //             cmd:"npm run build",
        //             base: "sites/",
        //             dir:"public/",
        //             deploy_key_id: `${process.env.GATSBY_GITLAB_DEPLOY_KEY_ID}`
        //         },
        //         build_settings: {
        //             cmd: "npm run build",
        //             dir: "public/",
        //             env: {
        //                 GATSBY_API_ENDPOINT: process.env.GATSBY_API_ENDPOINT,
        //                 GATSBY_API_SITE_ID: site_id
        //             },
        //             created_at: "2019-11-06T12:07:53.096Z",
        //             updated_at: "2020-02-04T11:33:30.557Z",
        //             public_repo: false,
        //             private_logs: null,
        //             allowed_branches: [
        //                 'master'
        //             ],
        //             functions_dir: null,
        //             installation_id: null,
        //             skip_prs: true,
        //             review_untrusted_builds: null,
        //             base_rel_dir: true,
        //             provider: "gitlab",
        //             repo_type: "git",
        //             repo_url: "https://gitlab.com/mamacph/builder",
        //             repo_branch: "master",
        //             repo_path: "mamacph/builder",
        //             deploy_key_id: `${process.env.GATSBY_GITLAB_DEPLOY_KEY_ID}`
        //         }
        //     },
        //     headers: {
        //         Authorization: `Bearer ${process.env.GATSBY_NETLIFY_TOKEN}`
        //     }
        // })
        .then((response) => {
            if(response.error) {
                console.log('createSite: throwing error: ', response.error)
                throw(response.error);
            }
            // if()
            console.log('createSite: create successfull: ', response.data.data, response.data.data.site_id)
            dispatch(saveAction(accessToken, {netlify_site_live: true}, response.data.data.site_id, 'PUT', null, null, true))

            dispatch(createSiteSuccess(response.data.data.site_id));

            axios.post('/.netlify/functions/netlify', {
                method: 'PATCH',
                endpoint: null,
                payload: { 
                    domain_aliases: [`www.${domain}.worksiter.${domainEnding}`]
                },  
                netlifySiteId: response.data.data.site_id
            }).then((res) => {
                console.log(res)
            }).catch((err) => {
                console.log(err)
            })

            return response.data.data.site_id;
        })
        .catch((err) => {
            console.log('createSite: failure: ', err.response)
            dispatch(createSiteFailure(err.response.status))
        })
    }

}

export default createSite;