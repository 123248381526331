import {loginRequest, loginSuccess, loginFailure} from '../actions/auth';
const axios = require('axios');

function authLogin(email, password, remember, callback) {
    return dispatch => {

        dispatch(loginRequest());

        axios.post(`${process.env.GATSBY_API_ENDPOINT}/api/auth/token/obtain/`, {
            username: email,
            password: password
        })
        .then((res) => {
            if(res.error) {
                console.log('AuthLogin: throwing error: ', res.error)
                throw(res.error);
            }   
            console.log('AuthLogin: Login successfull: ', res.data)
            console.log('REMEMBER', remember)
            if(res.data.access && res.data.refresh) {
                new Promise((resolve) => {
                    if(remember) {
                        console.log('setting access tokens')
                        localStorage.setItem('access', res.data.access);
                        localStorage.setItem('refresh', res.data.refresh);
                    }
                    resolve();
                })
                .then(() => {
                    dispatch(loginSuccess(res.data));
                })
                .catch((err) => {
                    console.log('ERROR FROM LOGIN')
                    throw new Error("Error");
                })
                
                
                return res.data;
            } else {
                console.log('ERROR FROM LOGIN')
                throw new Error("Error");
            }
           
        })
        .then(() => {
            if(callback) {
                callback()
            }
        })
        .catch((err) => {
            console.log('AuthLogin: cathing error:  ', err.username)
            dispatch(loginFailure(err.response.data.detail));
        });
    }

}

export default authLogin;