import SectionData from './sectionData' 

const initialSiteData = (i18n, company_name, industry, contact_phone, contact_email) => {
    const content = {
        industry: industry
    }

    const sectionData = SectionData(null, content, null, i18n)


    const imageTextSection1 = {
        title: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_1.title.${content.industry}` }) : null,
        subtitle: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_1.text.${content.industry}` }) : null,
        button_text: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_1.button_text.${content.industry}` }) : null,
        image_url: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_1.image.${content.industry}` }) : null,
        alt_text: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_1.alt_text.${content.industry}` }) : null,
        section_type: "image_text_right",
        index: 3
      }

    const imageTextSection2 = {
        title: content.industry ? i18n({ id: `sites_content.services_page.image_text_left_1.title.${content.industry}` }) : null,
        subtitle: content.industry ? i18n({ id: `sites_content.services_page.image_text_left_1.text.${content.industry}` }) : null,
        button_text: content.industry ? i18n({ id: `sites_content.services_page.image_text_left_1.button_text.${content.industry}` }) : null,
        image_url: content.industry ? i18n({ id: `sites_content.services_page.image_text_left_1.image.${content.industry}` }) : null,
        alt_text: content.industry ? i18n({ id: `sites_content.services_page.image_text_left_1.alt_text.${content.industry}` }) : null,
        section_type: "image_text_left",
        index: 4
      }

    const imageTextSection3 = {
        title: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_2.title.${content.industry}` }) : null,
        subtitle: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_2.text.${content.industry}` }) : null,
        button_text: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_2.button_text.${content.industry}` }) : null,
        image_url: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_2.image.${content.industry}` }) : null,
        alt_text: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_2.alt_text.${content.industry}` }) : null,
        section_type: "image_text_right",
        index: 5
      }

    const imageTextSection4 = {
        title: content.industry ? i18n({ id: `sites_content.services_page.image_text_left_2.title.${content.industry}` }) : null,
        subtitle: content.industry ? i18n({ id: `sites_content.services_page.image_text_left_2.text.${content.industry}` }) : null,
        button_text: content.industry ? i18n({ id: `sites_content.services_page.image_text_left_2.button_text.${content.industry}` }) : null,
        image_url: content.industry ? i18n({ id: `sites_content.services_page.image_text_left_2.image.${content.industry}` }) : null,
        alt_text: content.industry ? i18n({ id: `sites_content.services_page.image_text_left_2.alt_text.${content.industry}` }) : null,
        section_type: "image_text_left",
        index: 6
      }

    const imageTextSection5 = {
        title: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_3.title.${content.industry}` }) : null,
        subtitle: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_3.text.${content.industry}` }) : null,
        button_text: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_3.button_text.${content.industry}` }) : null,
        image_url: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_3.image.${content.industry}` }) : null,
        alt_text: content.industry ? i18n({ id: `sites_content.services_page.image_text_right_3.alt_text.${content.industry}` }) : null,
        section_type: "image_text_right",
        index: 7
      }


      const imageTextSectionArr = [
        imageTextSection1,
        imageTextSection2,
        imageTextSection3,
        imageTextSection4,
        imageTextSection5,
      ]

      console.log('imageTextSectionArr', imageTextSectionArr)
      console.log('imageTextSection1', imageTextSection1)
      console.log('imageTextSection2', imageTextSection2)
      console.log('imageTextSection3', imageTextSection3)
      console.log('imageTextSection4', imageTextSection4)
      console.log('imageTextSection5', imageTextSection5)
      
      const imageTextSectionFiltered = imageTextSectionArr.filter(el => el.title !== 'null' ? el : false)

      console.log('imageTextSectionFiltered', imageTextSectionFiltered)

    

    return ({
      footer_title_one: sectionData.footer.datafields.footer_title_one,
      footer_text_one: sectionData.footer.datafields.footer_text_one,
      footer_title_two: sectionData.footer.datafields.footer_title_two,
      footer_text_two: sectionData.footer.datafields.footer_text_two.replace('[email]', contact_email).replace('[phone]', contact_phone),
      footer_title_three: sectionData.footer.datafields.footer_title_three,
      footer_text_three: sectionData.footer.datafields.footer_text_three,
      pages:
        [
            {
              index: 1, 
              name: "Forside", 
              slug: "__home__", 
              image_url: i18n({ id: `sites_content.frontpage.image.${industry}` }),
              seo_description: i18n({ id: `sites_content.header.subtitle.${content.industry}` }),
              section: [
                {
                  ...sectionData.header.datafields,
                  // image_url: sectionData.header.datafields.image_url,
                  section_type: "header",
                  index: 1,
                  title: company_name
                },
                {
                  ...sectionData.why_us.datafields,
                  section_type: "why_us",
                  index: 2
                },
                {
                  ...sectionData.image_text_left.datafields,
                  // image_url: sectionData.image_text_left.datafields.image_url,
                  section_type: "image_text_left",
                  index: 3
                },
                {
                  ...sectionData.contact_simple.datafields,
                  section_type: "contact_simple",
                  index: 4
                },
                {
                  ...sectionData.image_text_right.datafields,
                  // image_url: sectionData.image_text_right.datafields.image_url,
                  section_type: "image_text_right",
                  index: 5
                },
                {
                  ...sectionData.services.datafields,
                  section_type: "services",
                  index: 6
                }
              ]
            },
            {
              index: 2, 
              name: "Ydelser", 
              slug: "ydelser", 
              image_url: i18n({ id: `sites_content.services_page.header.image.${industry}` }),
              seo_description: i18n({ id: `sites_content.services_page.seo_text.${content.industry}` }),
              section: [
                {
                  title: content.industry ? i18n({ id: `sites_content.services_page.header.title.${content.industry}` }) : null,
                  subtitle: content.industry ? i18n({ id: `sites_content.services_page.header.subtitle.${content.industry}` }) : null,
                  link_text: content.industry ? i18n({ id: `sites_content.services_page.header.link_text.${content.industry}` }) : null,
                  image_url: content.industry ? i18n({ id: `sites_content.services_page.header.image.${content.industry}` }) : null,
                  alt_text: content.industry ? i18n({ id: `sites_content.services_page.header.alt_text.${content.industry}` }) : null,
                  section_type: "header",
                  index: 1,
                },
                {
                  ...sectionData.services.datafields,
                  section_type: "services",
                  index: 2
                },
                ...imageTextSectionFiltered,
                {
                  ...sectionData.contact_simple.datafields,
                  section_type: "contact_simple",
                  index: imageTextSectionFiltered.length + 3
                },
              ]
            },
            {
              index: 3,
              name: "Kontakt",
              slug: "kontakt",
              image_url: i18n({ id: `sites_content.page.image.${industry}` }),
              seo_description: i18n({ id: `sites_content.contact_section.text.${content.industry}` }),
              section: [
                {
                  ...sectionData.contact_section.datafields,
                  section_type: "contact_section",
                  index: 1
                },
                {
                  ...sectionData.why_us.datafields,
                  section_type: "why_us",
                  index: 2
                }
              ]
            }
          ]
        }
    )
}

export default initialSiteData