import {registerRequest, registerSuccess, registerFailure} from '../actions/auth';
import loginAction from './authLogin';
import { useIntl } from "gatsby-plugin-intl"
import {flatMapDeep, flatMap, get} from 'lodash'

const axios = require('axios');

const flat = (el) => {

    console.log('el: ', el)
    if (Array.isArray(el)) {
        console.log('is array')
        if(Array.isArray(el[0]) || typeof el[0] === 'object') {
            console.log('is nested array or object')
            return flat(el[0])
        } else {
            console.log('returning value jnk: ', typeof el[0], el[0])
            return el[0]
        }
    } else if (typeof el === "object") {
        console.log('OBJECT!')
        for (var key in el) {
            if (typeof el[key] === "object") {
                console.log('running again')
                return flat(el[key]);   
            } else {
                console.log('returning: ', typeof el)
                return el
            }
        }
    } else {
        console.log('returning el: ', typeof el, el)
        return el
    }
}

function authRegister(sub, initialData, country, callback) {

    console.log('SUBMISSION REGISTRATION', {
        sub
    })
    return dispatch => {

        dispatch(registerRequest());

        axios.post(`${process.env.GATSBY_API_ENDPOINT}/api/auth/create_user/`, {
            username: sub.email,
            password: sub.password,
            profile: {
                country: country,
                websites: [{
                    name: sub.company,
                    company_name: sub.company,
                    industry: sub.industry,
                    domain_name: sub.domain_name,
                    contact_email: sub.contact_email,
                    contact_phone: sub.contact_phone,
                    chosen_template: sub.design_template,
                    primary_color: sub.primary_color,
                    secondary_color: sub.secondary_color,
                    footer_title_one: initialData.footer_title_one,
                    footer_text_one: initialData.footer_text_one,
                    footer_title_two: initialData.footer_title_two,
                    footer_text_two: initialData.footer_text_two,
                    footer_title_three: initialData.footer_title_three,
                    footer_text_three: initialData.footer_text_three,
                    page: initialData.pages
                }]
            }
        })
        .then((res) => {
            if(res.error) {
                console.log('AuthRegister: throwing error: ', res.error)
                throw(res.error);
            }
            console.log('AuthRegister: Register successfull: ', res, res.data)

            dispatch(registerSuccess(res.data));
            
            return res.data;
        })
        .then(() => {
            dispatch(loginAction(sub.email, sub.password, sub.remember, callback))
        })
        // .then(() => {
        //     callback()
        //     // callback(sub.email, res.data.profile.websites[0].site_id)
        // })
        .catch((err) => {
            const errors = flatMap(err.response.data).map(e => flat(e))
            console.log('AuthRegister: cathing error:  ', err, errors)
            dispatch(registerFailure(flatMapDeep(errors)))
        });
    }

}

export default authRegister;