import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby-plugin-intl"


import registerAction from '../state/async/authRegister';
import createSiteAction from '../state/async/netlifyCreateSite'
import loginAction from '../state/async/authLogin';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SEO from "../components/seo"
import Form from '../components/hoc/Form/Form'
import FlowIndicator from "../components/hoc/flowIndicator/flowIndicator"

import { navigate } from "gatsby-plugin-intl/link";
import slugify from '../components/hoc/slugify/Slugify'

import initialSiteData from '../app/dashboard/initialSiteData'
import {getAccessToken} from '../state/reducers/auth';
import { isLoggedIn, loginPending, getErrors, netlifySiteId, getData, formError, registerPending, registerSuccess } from "../state/reducers/auth";
import axios from 'axios'
import Notification from "../components/hoc/notification/notification";

const queryString = require('query-string');

const Register = ({data, register, login, isLoggedIn, loginPending, errors, createSiteAction, access, netlifySiteId, formError, registerPending, registerSuccess, location}) => {

    const dataQuery = useStaticQuery(graphql`
    query {
        modernDesign: file(relativePath: { eq: "modern_design.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    presentationWidth
                    presentationHeight
                }
            }
        }
        classicDesign: file(relativePath: { eq: "classic_design.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    presentationWidth
                    presentationHeight
                }
            }
        }
    }
  `)

    const modernDesign = dataQuery.modernDesign.childImageSharp
    const classicDesign = dataQuery.classicDesign.childImageSharp

    const i18n = useIntl().formatMessage
    const currentLang = useIntl().locale

    const referers = [
        {value: 'os', label: "Ordrestyring"},
        {value: 'friend', label: "En bekendt"},
        {value: 'other', label: "Andet"}
    ]

    const queryParams = queryString.parse(location.search);
    const refererParam = referers.find(p => p.value === queryParams.referer) && queryParams.referer
    
    const [displayedForm, setDisplayedForm] = useState(0)
    const [submission, setSubmission] = useState({email: '', password: '', password_repeat: '', remember: false, terms: false, company: '', industry: '', domain_name: '', contact_phone: '', contact_email: '', design_template: '', primary_color: '', secondary_color: '', referer: refererParam || ''})
    const [loading, setLoading] = useState(false)


    const stripeCheckoutHandler = (mail, id, referer) => {
        setLoading(true)
        axios.post('/.netlify/functions/stripeSessionId', {
            email: mail,
            site_id: id,
            env: process.env.GATSBY_ENVIRONMENT_SET,
            referer: referer
        })
        .then((res) => {
            stripePaymentHandler(res.data.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const stripePaymentHandler = (id) => {
        
        const stripe = window.Stripe(process.env.GATSBY_ENVIRONMENT_SET === 'development' ? process.env.GATSBY_STRIPE_PUB_KEY :  process.env.GATSBY_STRIPE_PUB_KEY_PROD);

        const async = async () => {
            const {error} = await stripe.redirectToCheckout({
                // Make the id field from the Checkout Session creation API response
                // available to this file, so you can provide it as parameter here
                // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                sessionId: id
            })

            if(error) {
                setLoading(false)
            }
        }
        async()
    }

    const nextHander = (data) => {
        setSubmission({...submission, ...data})
        setDisplayedForm(displayedForm + 1)
    }

    const backHandler = (data) => {
        setSubmission({...submission, ...data})
        setDisplayedForm(displayedForm - 1)
    }

    const submitHandler = (data) => {

        new Promise((resolve) => {
            setSubmission({...submission, ...data })
            resolve();
        })
        .then(() => {
            register({...submission, ...data}, initialSiteData(i18n, submission.company, submission.industry, submission.contact_phone, submission.contact_email), currentLang, nextHander)    
            
        })
        // .catch((err) => {
        //     console.log('Error: ', err.message)
        // })
    }

    const formRegister = (displayedForm === 0 ? 
        <>
            <Form 
            submitHandler={nextHander}
            submitText={ `${i18n({ id: "general.next" })}  →`}
            formElements={[
                { type: 'select', name: 'referer', value: submission && submission.referer ? submission.referer : '', label: "Hvor har du hørt om Worksiter?", required: true, options: [
                    {value: 'os', label: "Ordrestyring"},
                    {value: 'friend', label: "En bekendt"},
                    {value: 'other', label: "Andet"}
                ], help: i18n({ id: "pages.register.industry_description" }) },
                { type: 'email', name: 'email', label: 'Email', value: submission ? submission.email : '', required: true },
                { type: 'password', name: 'password', label: 'Password', value: submission ? submission.password : '', required: true },
                { type: 'password', name: 'password_repeat', label: i18n({ id: "general.repeat_password" }), required: true, value: submission ? submission.password_repeat : '', errorText: i18n({ id: "general.validations.repeat_password" }) },
                { type: 'checkbox', name: 'remember', label: i18n({ id: "general.remember_me" }), value: submission ? submission.remember : false, required: false },
                { type: 'checkbox', name: 'terms', label: i18n({ id: "pages.register.terms" }), value: submission ? submission.terms : false, required: true }
            ]}
            // linkCaption={ i18n({ id: "general.already_user" })}
            // link="/login"
            // linkTitle={`${ i18n({ id: "general.to_login" })} →`}
            />
            <h2 className="h5 mt-big">Betingelser</h2>
            <p>Når du tilmelder dig Worksiter accepterer du vores <Link to="/terms-of-service">{ i18n({ id: "pages.terms.title" })}</Link> og <Link to="/data-policy">{ i18n({ id: "pages.data-policy.title" })}</Link>
            </p>
        </>
        : null)

    const formInfo = (displayedForm === 1 ? <Form 
        submitHandler={nextHander}
        backHandler={backHandler}
        submitText={ `${i18n({ id: "general.next" })}  →` }
        formElements={[
            { type: 'text', name: 'company', label: i18n({ id: "pages.register.company_name" }), required: true, value: submission && submission.company ? submission.company : '', help: i18n({ id: "pages.register.company_description" }) },
            { type: 'select', name: 'industry', value: submission && submission.industry ? submission.industry : '', label: i18n({ id: "pages.register.industry" }), required: true, options: [
                {value: 'electrician', label: i18n({ id: "pages.register.industries.electrician" }) },
                {value: 'plumber', label: i18n({ id: "pages.register.industries.plumber" }) },
                {value: 'carpenter', label: i18n({ id: "pages.register.industries.carpenter" }) },
                {value: 'other', label: i18n({ id: "pages.register.industries.other" }) }
            ], help: i18n({ id: "pages.register.industry_description" }) },
            { type: 'text', name: 'domain_name', postFix: `.worksiter.${i18n({ id: `lang_to_tld.${currentLang}` })}`, value: submission && submission.domain_name ? slugify(submission.domain_name.replace('www.', '').replace('https://', '').replace('http://', '').split('.')[0]) : submission && submission.company ? slugify(submission.company) : '', label: i18n({ id: "pages.register.wanted_domain" }), required: false, help: i18n({ id: "pages.register.wanted_domain_description" }) },
            { type: 'text', name: 'contact_phone', value: submission && submission.contact_phone ? submission.contact_phone : '', label: i18n({ id: "pages.register.contact_phone" }), required: false, help: i18n({ id: "pages.register.contact_phone_description" }) },
            { type: 'email', name: 'contact_email', value: submission && submission.contact_email ? submission.contact_email : '', label: i18n({ id: "pages.register.contact_email" }), required: false, help: i18n({ id: "pages.register.contact_email_description" }) }
        ]}
        />
        : null)

        const formDesign = (displayedForm === 2 ? <Form 
            submitHandler={submitHandler}
            loading={registerPending}
            success={registerSuccess}
            backHandler={backHandler}
            submitText={ i18n({ id: "general.sign_up" }) }
            formElements={[
                { type: 'select_image', name: 'design_template', value: submission && submission.design_template ? submission.design_template : 'classic', label: i18n({ id: "pages.register.design_template" }), required: true, options: [
                    {value: 'classic', label: i18n({ id: "pages.register.classic_design" }), image: classicDesign },
                    {value: 'modern', label: i18n({ id: "pages.register.modern_design" }), image: modernDesign }
                ], help: i18n({ id: "pages.register.industry_description" }) },
                { type: 'color_theme', name: 'color_theme', label: i18n({ id: "pages.register.color" }), help: i18n({ id: "pages.register.can_change_later" }), required: true, customColor: {help: i18n({ id: "pages.register.own_colors" }), primary: i18n({ id: "pages.register.color_name_one" }), secondary: i18n({ id: "pages.register.color_name_two" })}, primaryValue: submission.primary_color || '#ff7b00', secondaryValue: submission.secondary_color || '#0060f6', options: [
                    {primary: '#ff7b00', secondary: "#0060f6"},
                    {primary: '#29CEA2', secondary: "#3e6289"},
                    {primary: '#db2e22', secondary: "#508faf"},
                    
                ]}
            ]}
        />
        : null)

        const payment = (displayedForm === 3 ?
            <>
            <table className="table">
                <thead>
                    <tr>
                    <th>Abonnementspris per måned</th>
                    <th className="no-pl">269,- ex moms</th>
                    </tr>
                </thead>
                
                <tbody className="list-checkmark">
                    <tr>
                        <th scope="row" className="text-normal">Ingen binding eller gebyrer</th>
                        <td className="text-normal checkmark"></td>
                    </tr>
                    <tr>
                        <th scope="row" className="text-normal ">Domæne inkluderet (www.ditdomæne.worksiter.dk)</th>
                        <td className="text-normal checkmark"></td>
                    </tr>
                    <tr>
                        <th scope="row" className="text-normal">Tilknyt eget domæne</th>
                        <td className="text-normal text-orange no-pl">Kommer snart</td>
                    </tr>
                    <tr>
                        <th scope="row" className="text-normal">Optimeret til Google</th>
                        <td className="text-normal checkmark"></td>
                    </tr>
                    <tr>
                        <th scope="row" className="text-normal">Optimeret til mobil</th>
                        <td className="text-normal checkmark"></td>
                    </tr>
                    <tr>
                        <th scope="row" className="text-normal">Indbygget billeddatabase</th>
                        <td className="text-normal checkmark"></td>
                    </tr>
                </tbody>
            </table>
            <Form 
                submitHandler={() => stripeCheckoutHandler(data.username, data.profile.websites[0].site_id, submission.referer)}
                submitText="Start abonnement"
                loading={loading}
                formElements={[]}
                />

            {/* <button className="btn btn-primary mb-small" onClick={() => stripeCheckoutHandler(data.username, data.profile.websites[0].site_id)}>Start abonnement</button> */}
            <p className="small space-xs-up mt-small">- De første 7 dage er gratis</p>
            <p>Når du tilmelder dig Worksiter accepterer du vores <Link to="/terms-of-service">{ i18n({ id: "pages.terms.title" })}</Link> og <Link to="/data-policy">{ i18n({ id: "pages.data-policy.title" })}</Link>
            </p>
            </>
         
            : null )
    
    return (
    <>
        <SEO title="Registrér" />
        <section className="section-padding-big">
            <div className="container">
            <div className="row center-xs middle-xs">
                <div className="col col-xs-12 col-md-8 col-lg-6 space-xs-up">
                    <Notification />
                </div>
            </div>
                <div className="row center-xs">
                <div className={"col col-xs-12 col-md-10 col-lg-8 col-xl-6"}>
                    <h1 className="text-center h2">{ i18n({ id: "pages.register.title_beta" }) }</h1>
                    <p className="text-center" >{ i18n({ id: "pages.register.description" })}</p>
                    
                    <>
                    {(displayedForm === 0 ?
                    <>
                       <FlowIndicator  steps={ [
                            {name: i18n({ id: "pages.register.steps.registration" }), active: true, complete: false},
                            {name: i18n({ id: "pages.register.steps.info" }), active: false, complete: false},
                            {name: i18n({ id: "pages.register.steps.design" }), active: false, complete: false},
                            {name: i18n({ id: "pages.register.steps.payment" }), active: false, complete: false}
                        ]}/>
                        </>
                    : displayedForm === 1 ? 
                    <>
                        <FlowIndicator  steps={ [
                            {name: i18n({ id: "pages.register.steps.registration" }), active: false, complete: true},
                            {name: i18n({ id: "pages.register.steps.info" }), active: true, complete: false},
                            {name: i18n({ id: "pages.register.steps.design" }), active: false, complete: false},
                            {name: i18n({ id: "pages.register.steps.payment" }), active: false, complete: false}
                        ]}/>
                    </>
                    : displayedForm === 2 ? 
                    <>
                        <FlowIndicator  steps={ [
                            {name: i18n({ id: "pages.register.steps.registration" }), active: false, complete: true},
                            {name: i18n({ id: "pages.register.steps.info" }), active: false, complete: true},
                            {name: i18n({ id: "pages.register.steps.design" }), active: true, complete: false},
                            {name: i18n({ id: "pages.register.steps.payment" }), active: false, complete: false}
                        ]}/>
                    </>
                     : displayedForm === 3 ? 
                     <>
                         <FlowIndicator  steps={ [
                             {name: i18n({ id: "pages.register.steps.registration" }), active: false, complete: true},
                             {name: i18n({ id: "pages.register.steps.info" }), active: false, complete: true},
                             {name: i18n({ id: "pages.register.steps.design" }), active: false, complete: true},
                             {name: i18n({ id: "pages.register.steps.payment" }), active: true, complete: false}
                         ]}/>
                     </>
                    : null)}
                    <div className="card">
                    {formRegister}
                    {formInfo}
                    {formDesign}
                    {payment}
                    { formError ? <p className="text-red mt">{formError.map((e, i) => i18n({ id: `general.errors.${slugify(e)}`}) + ' ' )}</p> : null }
                    </div>
                    </>
                    {/* : 
                    <>
                    <a className="btn btn-primary" href="mailto:info@worksiter.com?subject=Worksiter beta-tester">{i18n({ id: "pages.register.become_beta_tester" })}</a>
                    </> */}
                    
                    </div>
                </div>
            </div>
        </section>
    </>
    )
    }

const mapDispatchToProps = dispatch => bindActionCreators({
    register: registerAction, 
    login: loginAction,
    createSiteAction: createSiteAction,
}, dispatch)

const mapStateToProps = state => ({
    errors: getErrors(state.auth),
    data: getData(state.auth),
    isLoggedIn: isLoggedIn(state.auth),
    loginPending: loginPending(state.auth),
    access: getAccessToken(state.auth),
    netlifySiteId: netlifySiteId(state.auth),
    formError: formError(state.auth),
    registerPending: registerPending(state.auth),
    registerSuccess: registerSuccess(state.auth),
  })

export default connect(mapStateToProps, mapDispatchToProps)(Register);
